<template>
  <v-layout>
    <v-row justify="center" no-gutters>
      <v-col cols="12">
        <kurcc-back-button :to="{name: 'login-index'}"/>
      </v-col>
      <v-col class="mt-n16" cols="10" md="4" sm="6" xl="2">
        <v-row align="center" justify="center" no-gutters>
          <v-col>
            <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
              <v-form @submit.prevent="handleSubmit(requestCode)">
                <validation-provider v-slot="{ errors }" name="Number" rules="required">
                  <v-text-field v-model="phone_number" :error-messages="errors"
                                :label="$vuetify.lang.t('$vuetify.pages.auth.number')" autofocus outlined
                                placeholder="9391079907"/>
                </validation-provider>
                <v-btn :disabled="invalid" :loading="loading" color="primary" tile type="submit"
                       width="100%">
                  {{ $vuetify.lang.t('$vuetify.pages.auth.send') }}
                </v-btn>
                <p class="mt-5 text-start">
                  {{ $vuetify.lang.t('$vuetify.pages.auth.smsCostNotification') }}
                </p>
              </v-form>
            </validation-observer>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'KurccLoginPage',
  components: {
    KurccBackButton: () => import('@/modules/app/components/KurccBackButton'),
    ValidationObserver
  },
  data () {
    return {
      phone_number: null,
      loading: false
    }
  },
  methods: {
    requestCode () {
      this.loading = true
      this.$store.dispatch('requestPassword', { phone_number: this.phone_number })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
